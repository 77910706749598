export default function Body({ active, children }) {
  return (
    <div
      className={
        active
          ? "relative top-12 flex flex-grow flex-col items-center overflow-scroll px-4 py-8 transition-all duration-500"
          : "relative -top-4 flex flex-grow flex-col items-center overflow-scroll px-4 py-8 transition-all duration-500"
      }
    >
      {children}
    </div>
  );
}
