export default function CV() {
  return (
    <div className="text-white">
      <div className="inline-block pb-4">
        <h1 className="font-semibold text-xl sm:text-2xl">CV</h1>
      </div>
      <div className="flex flex-col m-auto px-2 py-4 rounded-md shadow-black bg-bluegray-500 text-xs md:text-base md:p-4">
        <div className="flex flex-col max-w-xl font-light text-justify gap-y-2">
          <h2 className="font-extrabold text-base border-b-2 inline sm:text-lg">Experience</h2>
          <ul className="flex flex-col font-light gap-y-1 mb-2">
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">GoDaddy, Kirkland, WA</span>
                <span>Software Engineer II</span>
              </div>
              <span>2022.2 - present</span>
            </li>
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">Oracle, Redwood Shores (remote), CA</span>
                <span>Software Engineer Intern</span>
              </div>
              <span>2021.6 - 2021.8</span>
            </li>
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">UW College of Engineering, Seattle, WA</span>
                <span>Research Assistant</span>
              </div>
              <span>2019.11 - 2021.8</span>
            </li>
          </ul>
          <h2 className="font-extrabold text-base border-b-2 inline sm:text-lg">Education</h2>
          <ul className="flex flex-col gap-y-1 font-light">
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">Northeastern University, Seattle, WA</span>
                <span>M.S. Computer Software Engineering</span>
              </div>
              <span>2020.9 - 2021.12</span>
            </li>
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">University of Washington, Seattle, WA</span>
                <span>M.S. Materials Science w/ Data Science training</span>
              </div>
              <span>2019.9 - 2020.8</span>
            </li>
            <li className="flex flex-col sm:flex-row sm:gap-x-10 sm:justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">
                  Huazhong University of Sci. &amp; Tech., Wuhan, China
                </span>
                <span>B.E. Materials Science</span>
              </div>
              <span>2015.9 - 2019.6</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
