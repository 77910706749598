import { Link } from "react-router-dom";

export default function Menu({ active, setIsActive }) {
  const menuItems = ["homepage", "CV"];

  return (
    <menu
      className={
        active
          ? "relative -top-0 m-0 pl-4 transition-all duration-500 sm:pl-8"
          : "relative -top-12 m-0 pl-4 transition-all duration-500 sm:pl-8"
      }
    >
      <ul
        className={
          active
            ? "flex flex-row pt-4 gap-x-4 visible transition-all duration-500"
            : "flex flex-row pt-4 gap-x-4 invisible transition-all duration-500"
        }
      >
        {menuItems.map((item, index) => (
          <li
            className="text-sm font-semibold capitalize text-white sm:text-lg sm:font-bold"
            key={index}
          >
            <Link to={item} onClick={() => setIsActive(!active)}>
              {item}
            </Link>
          </li>
        ))}
      </ul>
      <button
        className={
          active
            ? "mt-6 transform rotate-180 transition-all duration-300 bg"
            : "mt-6 transform rotate-0 transition-all duration-300 bg"
        }
        onClick={() => setIsActive(!active)}
      >
        <svg
          className="h-5 w-5 sm:h-7 sm:w-7"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
            fill="#fff"
          />
        </svg>
      </button>
    </menu>
  );
}
