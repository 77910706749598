export default function Footer({ active }) {
  return (
    <footer
      className={
        active
          ? "relative top-12 flex justify-center items-center p-4 bg-gray-900 text-white transition-all duration-500"
          : "relative top-0 flex justify-center items-center p-4 bg-gray-900 text-white transition-all duration-500"
      }
    >
      <span className="text-center">
        Dawei Gu © 2021 - {new Date().getFullYear()}. Built with{" "}
        <a href="https://reactjs.org/" className="underline">
          React
        </a>
        ,{" "}
        <a href="https://tailwindcss.com/" className="underline">
          TailwindCSS
        </a>{" "}
        and{" "}
        <a href="https://www.cloudflare.com/" className="underline">
          Cloudflare
        </a>
      </span>
    </footer>
  );
}
