import Body from "./Body";
import Menu from "./Menu";
import Homepage from "./Homepage";
import CV from "./CV";
import Default from "./Default";
import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";

export default function App() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  return (
    <div className="flex flex-col font-sans box-border min-h-screen bg-bg-ocean bg-cover">
      <Router>
        <Menu active={isMenuActive} setIsActive={setIsMenuActive} />
        <Body active={isMenuActive}>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/homepage" component={Homepage} />
            <Route exact path="/CV" component={CV} />
            <Route component={Default} />
          </Switch>
        </Body>
        <Footer active={isMenuActive} />
      </Router>
    </div>
  );
}
